import { useAccount } from "@context/account";
import DfmServices from "@services/APIs/Dfm";
import KPIsServices from "@services/APIs/Kpis";
import PermissionsServices from "@services/APIs/Permissions";
import SeedDataServices from "@services/APIs/SeedData";
import AuthServices from "@services/AuthServices";
import { routes } from "@utils/route";
import { useRouter } from "next/router";
import PropTypes from "prop-types";
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useQuery } from "react-query";

const SeedDataContext = createContext({});

export const useSeedData = () => useContext(SeedDataContext);

const SeedDataProvider = ({ children }) => {
  const route = useRouter();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const { accountId } = useAccount();

  useEffect(() => {
    const resp = AuthServices.handleAuthentication();
    setIsAuthenticated(resp === "Authenticated");
    const interval = setInterval(async () => {
      const resp = AuthServices.handleAuthentication();
      setIsAuthenticated(resp === "Authenticated");
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  const { data: states, isSuccess: isStateFetched } = useQuery(
    [`/type-data/states`],
    SeedDataServices.getStates,
    { refetchOnWindowFocus: false }
  );

  const { data: timezones, isSuccess: isTimezonesFetched } = useQuery(
    [`/type-data/time-zones`],
    SeedDataServices.getTimezones,
    { refetchOnWindowFocus: false }
  );

  const { data: executiveRoleTypes, isSuccess: isExecutiveRoleTypesFetched } =
    useQuery(
      [`/type-data/executive-role-types`],
      SeedDataServices.getExecutiveRoleTypes,
      { refetchOnWindowFocus: false }
    );

  const { data: advisorRoleTypes, isSuccess: isAdvisorRoleTypesFetched } =
    useQuery(
      [`/type-data/advisor-types`],
      SeedDataServices.getAdvisorRoleTypes,
      { refetchOnWindowFocus: false }
    );

  const { data: accountingRoleTypes, isSuccess: isAccountingRoleTypesFetched } =
    useQuery(
      [`/type-data/accounting-role-types`],
      SeedDataServices.getAccountingRoleTypes,
      { refetchOnWindowFocus: false }
    );

  const { data: entityTypes, isSuccess: isEntityTypesFetched } = useQuery(
    [`/type-data/entity-types`],
    SeedDataServices.getEntityTypes,
    { refetchOnWindowFocus: false }
  );

  const { data: taxTypes, isSuccess: isTaxTypesFetched } = useQuery(
    [`/type-data/tax-types`],
    SeedDataServices.getTaxTypes,
    { refetchOnWindowFocus: false }
  );

  const {
    isLoading: isReportTypesLoading,
    data: reportTypes,
    isSuccess: isReportTypesFetched,
    refetch: refetchReportTypes,
  } = useQuery(
    [`/type-data/report-types/${accountId}`],
    SeedDataServices.getReportTypes,
    { refetchOnWindowFocus: false, enabled: isAuthenticated && !!accountId }
  );

  const { data: dfmTypes, isSuccess: isDfmTypesFetched } = useQuery(
    [`/dynamic-financial-statement-types/${accountId}`, { clientId: "" }],
    DfmServices.getDfmTypes,
    { refetchOnWindowFocus: false, enabled: isAuthenticated && !!accountId }
  );

  const { data: permissions, isSuccess: isPermissionsFetched } = useQuery(
    [`/permission-categories`, { include: "permision", active: true }],
    PermissionsServices.getPermissionCategories,
    { refetchOnWindowFocus: false, enabled: isAuthenticated }
  );

  const {
    data: roleGroups,
    isSuccess: isRoleGroupsFetched,
    refetch: refetchRoleGroup,
  } = useQuery([`/role-groups`], SeedDataServices.getRoleGroups, {
    refetchOnWindowFocus: false,
  });

  const { data: kpisCategoryData, isSuccess: isKpisCategoryDataFetched } =
    useQuery(
      [`/get/std-kpi-categories`, { active: true }],
      KPIsServices.getKPIsCategory,
      {
        refetchOnWindowFocus: false,
        enabled:
          isAuthenticated &&
          (routes.admin.kpis.list === route.pathname ||
            routes.client.kpis.stdKPIsList === route.pathname),
      }
    );

  const { data: dataSetFieldTypes, isSuccess: isDataSetFieldTypesFetched } =
    useQuery(
      [`/dataset-field-types/${accountId}`],
      SeedDataServices.getDataSetFieldTypes,
      {
        refetchOnWindowFocus: false,
        enabled: isAuthenticated && !!accountId,
      }
    );

  const {
    data: chartTypes,
    isSuccess: isChartTypeFetched,
    refetch: refetchChartTypes,
  } = useQuery(
    [`/type-data/chart-types/${accountId}`],
    SeedDataServices.getChartTypes,
    { refetchOnWindowFocus: false, enabled: isAuthenticated && !!accountId }
  );

  const seedDataValues = useMemo(
    () => ({
      states: states?.items || [],
      timezones: timezones?.items || [],
      // Roles
      executiveRoleTypes: executiveRoleTypes?.items || [],
      advisorRoleTypes: advisorRoleTypes?.items || [],
      accountingRoleTypes: accountingRoleTypes?.items || [],
      // Others
      entityTypes: entityTypes?.items || [],
      taxTypes: taxTypes?.items || [],
      reportTypes: reportTypes?.items || [],
      dfmTypes: dfmTypes?.items || [],
      // permissions
      permissions: permissions?.items || [],
      //Kpis category
      kpisCategoryData: kpisCategoryData?.items || [],
      dataSetFieldTypes: dataSetFieldTypes?.items || [],
      chartTypes: chartTypes?.items || [],
      refetchChartTypes: refetchChartTypes,
      roleGroups: roleGroups?.items || [],
      refetchRoleGroup: refetchRoleGroup,
      refetchReportTypes: refetchReportTypes,
    }),
    [
      states,
      isStateFetched,
      timezones,
      isTimezonesFetched,
      executiveRoleTypes,
      isExecutiveRoleTypesFetched,
      advisorRoleTypes,
      isAdvisorRoleTypesFetched,
      accountingRoleTypes,
      isAccountingRoleTypesFetched,
      entityTypes,
      isEntityTypesFetched,
      taxTypes,
      isTaxTypesFetched,
      reportTypes,
      isReportTypesLoading,
      isReportTypesFetched,
      dfmTypes,
      isDfmTypesFetched,
      permissions,
      isPermissionsFetched,
      kpisCategoryData,
      isKpisCategoryDataFetched,
      dataSetFieldTypes,
      isDataSetFieldTypesFetched,
      chartTypes,
      isChartTypeFetched,
      roleGroups,
      isRoleGroupsFetched,
    ]
  );

  return (
    <SeedDataContext.Provider value={seedDataValues}>
      {children}
    </SeedDataContext.Provider>
  );
};

export default SeedDataProvider;

SeedDataProvider.propTypes = {
  children: PropTypes.any,
};
