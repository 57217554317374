import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  availableReportTypes: [],
  activeReportType: null,
  aisAccounts: [],
  summaryData: [],
  showConfidenceIndicator: false,
  state: {
    isLoading: false,
    filterType: null,
  },
  isCleared: false,
  isChanged: false,
};

export const aisAccountMappingSlice = createSlice({
  name: "aisAccountMapping",
  initialState,
  reducers: {
    setAvailableReportTypes: (state, action) => {
      state.availableReportTypes = action.payload;
    },
    setActiveReportType: (state, action) => {
      state.activeReportType = action.payload;
    },
    setAISAccounts: (state, action) => {
      state.aisAccounts = action.payload;
    },
    setSummaryData: (state, action) => {
      state.summaryData = action.payload;
    },
    setShowConfidenceIndicator: (state, action) => {
      state.showConfidenceIndicator = action.payload;
    },
    setState: (state, action) => {
      state.state = { ...state.state, ...action.payload };
    },
    setIsCleared: (state, action) => {
      state.isCleared = action.payload;
    },
    setIsChanged: (state, action) => {
      state.isChanged = action.payload;
    },
  },
});

export const {
  setAvailableReportTypes,
  setActiveReportType,
  setAISAccounts,
  setSummaryData,
  setShowConfidenceIndicator,
  setState,
  setIsCleared,
  setIsChanged,
} = aisAccountMappingSlice.actions;

export default aisAccountMappingSlice.reducer;
