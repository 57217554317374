import { HubCookie } from "@utils/cookies/types";

export const clientCookieManager = {
  getCookie: (name: HubCookie) => {
    const cookies = document.cookie.split("; ");
    const cookie = cookies.find((c) => c.startsWith(name));
    return cookie ? cookie.split("=")[1] : "";
  },
  setCookie: (name: HubCookie, value: string, days = 365) => {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    document.cookie = `${name}=${value}; expires=${date.toUTCString()}; path=/`;
  },
  deleteCookie: (name: HubCookie) => {
    document.cookie = `${name}=;path=/;`;
  },
};
