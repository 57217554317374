/* eslint-disable max-lines */
import { EBITDAFormulaDisplayModel } from "@services/APIs/EBITDA";
import { CustomKPI, StandardKPI } from "@services/APIs/Kpis";
import {
  ClientCustomKpiDisplayModel,
  KpiFormulaMetadata,
  SubAccountDisplayModel,
} from "@tgg_accounting/hub-node-api/dist/models";
import {
  ClientFinancialProfile,
  LongTermGoalModel,
} from "@tgg_accounting/tenant-api";
import { months } from "@utils/constant";
import {
  bracketItemIds,
  bracketItems,
  formulaItemTypes,
  isKPIInputItemConstant,
  kpiConstant,
  operatorItems,
} from "@utils/constant/formula";
import {
  dataSetVariablePropertyOption,
  nonAccountingKPIsVariables,
} from "@utils/constant/kpis";
import { KPITypeEnum, TRAILING_PERIOD } from "@utils/constant/reports";
import { getUniqRandomInteger } from "@utils/functions/common";
import { isEmpty } from "@utils/functions/validation";
import { v4 as uuidV4 } from "uuid";

export type KPISelectedAccount = {
  label: string;
  value: string;
  reportTypeCode: string;
  dataSourceType: string;
  outputFormat: string;
  decimals: number;
  isCumulative: boolean;
  isNonCumulative: boolean;
};

export type KPIInputItem = {
  id: string;
  selectedAccount?: null | KPISelectedAccount;
  aggregation: string;
  trailingPeriod?: null | {
    value: number | string;
  };
  value: string;
  testValue: number;
  isCustom?: boolean;
  isDisabled?: boolean;
};

export type KPIFormulaItem = {
  color?: string;
  type: "BRACKET" | "INPUT" | "OPERATOR";
  id: string;
};

export interface TrailingPeriod {
  type: string;
  valueType: string;
  value: string;
}

export interface KpiItem {
  metadata: KpiFormulaMetadata;
  id: string;
  name: string;
  description: string;
  refStdKpi: RefStdKpi;
  kpiType: KPITypeEnum;
  addedToReport: boolean;
}

export interface Category {
  id: string;
  name: string;
  description: string;
  auditInfo: AuditInfo;
  kpis: any[];
}

export interface RefStdKpi {
  id: string;
  name: string;
  description: string;
  addedToReport: boolean;
  auditInfo: AuditInfo;
  activeInfo: ActiveInfo;
  archiveInfo: ArchiveInfo;
  category: Category;
  metadata: KpiFormulaMetadata;
}
export interface ParentAccount {
  id: string;
  name: string;
  akaNames: string[];
  reportType: ReportType;
  subAccounts: any[];
  displayOrder: string;
}

export interface ReportType {
  id: string;
  name: string;
  description: string;
  code: string;
  activeInfo: ActiveInfo;
  auditInfo: AuditInfo;
}

export interface ActiveInfo {
  active: boolean;
  activeStatusChangedAt: string;
}

export interface AuditInfo {
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
}

export interface ArchiveInfo {
  archived: boolean;
  archivedStatusChangedAt: string;
}

export interface Constant {
  name: string;
  id: string;
  value: number;
}

export interface Calculation {
  id: string;
  name: string;
  code: string;
  reportType: ReportType;
  outputFormat: OutputFormat;
}

export interface Aggregation {
  id: string;
  name: string;
  code: string;
  description: string;
  activeInfo: ActiveInfo;
  auditInfo: AuditInfo;
}

export interface Column {
  id: string;
  name: string;
  code: string;
  description: string;
  datatype: Datatype;
  aggregation: Aggregation;
}

export interface Datatype {
  id: string;
  name: string;
  code: string;
  description: string;
  activeInfo: ActiveInfo;
  auditInfo: AuditInfo;
}

export interface Client {
  id: string;
  name: string;
  timeZoneId: string;
  currencyCode: string;
  locale: string;
  businessSummary: string;
  tagLine: string;
  website: string;
  logoUrl: string;
  address: {};
  phoneNumber: string;
  activeInfo: ActiveInfo;
  auditInfo: AuditInfo;
  archiveInfo: ArchiveInfo;
  parentClient: string;
  childClients: string[];
  financialProfile: ClientFinancialProfile;
  permissions: string[];
  longTermGoals: LongTermGoalModel[];
}

export interface AisAccount {
  id: string;
  source: string;
  metadata: KpiFormulaMetadata;
  name: string;
  label: string;
  client: Client;
  activeInfo: ActiveInfo;
  auditInfo: AuditInfo;
}

export interface OutputFormat {
  dataType: string;
  decimalFields: number;
}

export interface List {
  name: string;
  code: string;
  id: string;
  elements: string[];
  auditInfo: AuditInfo;
  archiveInfo: ArchiveInfo;
}

export interface ClientDataset {
  name: string;
  id: string;
  description: string;
  year: string;
  month: string;
  auditInfo: AuditInfo;
  archiveInfo: ArchiveInfo;
  columns: Column[];
  data: any[];
  lists: List[];
}

export interface ClientDataset {
  name: string;
  id: string;
  description: string;
  year: string;
  month: string;
  auditInfo: AuditInfo;
  archiveInfo: ArchiveInfo;
  columns: Column[];
  data: any[];
  lists: List[];
}

export interface Variable {
  type: string;
  id: string;
  source: string;
  name: string;
  value: string;
  aggregation: string;
  trailingPeriod: TrailingPeriod;
  parentAccount: ParentAccount;
  subAccount: SubAccountDisplayModel;
  aisAccount: AisAccount;
  constant: Constant;
  calculation: Calculation;
  clientDataset: ClientDataset;
  disabled: boolean;
}

export function kpisCategoryHelper(
  category: {
    id: string;
    name: string;
    description: string;
  } | null
) {
  return {
    id: category?.id || "",
    name: category?.name || "",
    description: category?.description || "",
  };
}

function kpiFormulaDisplay(metadata: KpiFormulaMetadata) {
  if (!metadata) return "";
  const formula = metadata.formula.split("");
  let changedText = [...formula];

  if (metadata?.variables?.length > 0) {
    formula.map((i, index) => {
      if (metadata.variables.filter((item) => item.name === i).length > 0) {
        const replaceItem = metadata.variables.find((item) => item.name === i);
        const userInput = replaceItem?.[TRAILING_PERIOD];
        let name = replaceItem?.[replaceItem?.type]?.name || "";

        changedText[index] = userInput ? `${name}(${userInput.type})` : name;
      }
    });
  }
  return changedText.join(" ");
}

export function kpisResponseHelper(response: {
  items: Array<StandardKPI> | null | undefined;
  totalPages: number;
  currentPageNumber: number;
  totalRecords: number;
}) {
  return {
    isItems: response?.items?.length > 0,
    items: response?.items || [],
    totalPages: response?.totalPages || 0,
    currentPage: response?.currentPageNumber || 0,
    totalRecords: response?.totalRecords || 0,
  };
}

export function kpisOutputFormatHelper(
  item: ClientCustomKpiDisplayModel | EBITDAFormulaDisplayModel
) {
  let format = {
    type: "",
    decimals: 0,
  };
  if (item?.metadata?.outputVariable) {
    switch (item.metadata.outputVariable.dataType) {
      case "numeric":
        format.type = "number";
        break;
      default:
        format.type = item.metadata.outputVariable.dataType;
        break;
    }
    format.decimals =
      item.metadata.outputVariable.formatting?.decimalFields || 0;
  }
  return format;
}

function isKPIBracket(string: string) {
  return bracketItems.some(
    (b) => b.label === string || b.optionalLabel === string
  );
}
function isKPIOperator(string: string) {
  return operatorItems.some((o) => o.label === string);
}
function getKPIBracket(string: string) {
  return bracketItems.find(
    (b) => b.label === string || b.optionalLabel === string
  );
}
function getKPIOperator(string: string) {
  return operatorItems.find((o) => o.label === string);
}

const kpiBracketColors = [
  "text-[#E3342F]",
  "text-[#E36209]",
  "text-[#FFA200]",
  "text-[#c9c910]",
  "text-[#84CC16]",
  "text-[#38C172]",
  "text-[#4DC0B5]",
  "text-[#3490DC]",
  "text-[#6574CD]",
  "text-[#9561E2]",
  "text-[#6F42C1]",
  "text-[#D63384]",
  "text-[#FA7EA8]",
];
export const getRandomKpiBracketColor = () =>
  kpiBracketColors[Math.round(Math.random() * (kpiBracketColors.length - 1))];

function kpiFormulaArrayHelper(
  item: ClientCustomKpiDisplayModel | EBITDAFormulaDisplayModel,
  inputArr = []
) {
  const formulaArr: Array<KPIFormulaItem> = [];
  if (item?.metadata?.formula) {
    const stringArr = Array.from(item.metadata.formula);
    const usedBracketColors: string[] = [];
    stringArr.forEach((s) => {
      if (isEmpty(s)) return;
      if (isKPIOperator(s)) {
        formulaArr.push({
          type: formulaItemTypes.operator,
          id: getKPIOperator(s)?.id || "",
        });
      } else if (isKPIBracket(s)) {
        let bracketColor;
        if (s === "(") {
          bracketColor =
            kpiBracketColors[
              usedBracketColors.length % kpiBracketColors.length
            ];
          usedBracketColors.push(bracketColor);
        } else {
          bracketColor = usedBracketColors.pop();
        }
        formulaArr.push({
          color: bracketColor,
          type: formulaItemTypes.bracket,
          id: getKPIBracket(s)?.id || "",
        });
      } else if (s) {
        const findVariable = inputArr.find((i) => i.formulaName === s);
        if (findVariable?.id) {
          formulaArr.push({
            type: formulaItemTypes.input,
            id: findVariable.id,
          });
        }
      }
    });
  }
  return formulaArr;
}

export function isAddNewInputDisabled(inputs: KPIInputItem[]) {
  if (inputs.length > 0) {
    return inputs.some((i) => {
      if (isKPIInputItemConstant(i)) {
        return !i?.value;
      }
      return !i?.trailingPeriod?.value;
    });
  }
  return false;
}

function getSelectedAccountId(variable: {
  type: string;
  id: string;
  [key: string]: any;
}) {
  if (variable?.type) {
    return variable?.id || variable[variable.type]?.id || "";
  }

  return "";
}

function kpiInputArrayHelper(
  item: ClientCustomKpiDisplayModel | EBITDAFormulaDisplayModel
) {
  const inputArr = [];
  if (item?.metadata?.variables?.length > 0) {
    item.metadata.variables.forEach((v) => {
      const testValue = getInputRandomNumber(inputArr);
      const isConstant = v.type === kpiConstant.type;
      inputArr.push({
        id: isConstant ? v.id : uuidV4(),
        selectedAccountId: isConstant
          ? kpiConstant.type
          : getSelectedAccountId(v),
        aggregation: v.aggregation || "",
        trailingPeriod: isConstant
          ? null
          : {
              value: ["current-month", "currentMonth"].includes(
                v.trailingPeriod?.type
              )
                ? "currentMonth"
                : v.trailingPeriod?.value || 12,
            },
        testValue: testValue,
        value: isConstant ? v.value : "",
        formulaName: v.name,
        isDisabled: !!v.disabled,
      });
    });
  }
  return inputArr;
}

function removeFormulaNameFromInputs(inputArr = []) {
  const formattedArr = [];
  if (inputArr.length > 0) {
    inputArr.forEach((i) => {
      delete i.formulaName;
      formattedArr.push(i);
    });
  }
  return formattedArr;
}

export function kpisItemResponseHelper(
  item: ClientCustomKpiDisplayModel | EBITDAFormulaDisplayModel
) {
  const inputArr = kpiInputArrayHelper(item);
  return {
    id: item?.id || "",
    name: item?.name || "",
    description: item?.description || "",
    descriptionMonthly: item?.descriptionMonthly || "",
    category: kpisCategoryHelper(item?.category),
    outputFormat: kpisOutputFormatHelper(item),
    isActive: !!item?.activeInfo?.active,
    formulaDisplay: kpiFormulaDisplay(item?.metadata),
    formulaArr: kpiFormulaArrayHelper(item, inputArr),
    inputsArr: removeFormulaNameFromInputs(inputArr),
    addedToReport: item?.addedToReport || false,
    variables: item?.metadata?.variables || [],
    isArchived: !!item?.archiveInfo?.archived,
    globalPeriod:
      item?.metadata?.period === "user-entered"
        ? 12
        : ["current-month", "currentMonth"].includes(item?.metadata?.period)
        ? "currentMonth"
        : 12,
  };
}

function getAggregationLabel(
  aggregation: string,
  trailingPeriod: TrailingPeriod
) {
  if (aggregation === "average" && trailingPeriod?.value === "currentMonth")
    return "";
  switch (aggregation) {
    case "sum":
      return "SUM ";
    case "average-first-last":
      return "AVG-F/L ";
    case "average":
      return "AVG ";
    default:
      return "";
  }
}

function getTrailingPeriodLabel(trailingPeriod: TrailingPeriod) {
  if (trailingPeriod?.value === "currentMonth") {
    return "CURRENT: ";
  }
  if (trailingPeriod?.value) {
    return `(${trailingPeriod?.value}m): `;
  }
  return "";
}

export function getFormulaInputValue(
  inputs: Array<KPIInputItem> = [],
  id: string
) {
  const inputItem = inputs.find((i) => i.id === id);
  let inputValue = null;
  if (inputItem?.id) {
    if (isKPIInputItemConstant(inputItem)) {
      inputValue = inputItem.value;
    } else {
      inputValue = inputItem.testValue;
    }
  }
  return inputValue || "N/A";
}

export function isInputLabelDisabled(
  inputs: Array<KPIInputItem> = [],
  id: string
) {
  const inputItem = inputs.find((i) => i.id === id);
  return !!inputItem?.isDisabled;
}

export function getFormulaInputLabel(
  inputs: Array<KPIInputItem> = [],
  id: string
) {
  const inputItem = inputs.find((i) => i.id === id);
  if (isKPIInputItemConstant(inputItem)) {
    const constantIndex = inputs
      .filter((i) => isKPIInputItemConstant(i))
      .findIndex((i) => i.id === id);
    return inputItem?.value || `Constant ${constantIndex + 1}`;
  }
  if (inputItem?.selectedAccount?.label) {
    return `${getAggregationLabel(
      inputItem.aggregation,
      inputItem.trailingPeriod
    )}${getTrailingPeriodLabel(inputItem.trailingPeriod)}${
      inputItem.selectedAccount.label
    }`;
  }
  const findIndex = inputs.findIndex((i) => i.id === id);
  return `Input ${findIndex + 1}`;
}

export function calculationResponseHelper(response: {
  items: Array<{
    id: string;
    name: string;
    code: string;
    reportType: ReportType;
    outputFormat: OutputFormat;
  }>;
  totalPages: number;
  currentPageNumber: number;
  totalRecords: number;
}) {
  return {
    isItems: response?.items?.length > 0,
    items: response?.items || [],
    totalPages: response?.totalPages || 0,
    currentPage: response?.currentPageNumber || 0,
    totalRecords: response?.totalRecords || 0,
  };
}

export function dataSetDetailsResponseHelper(item) {
  return {
    id: item?.id || "",
    name: item?.name || "",
    description: item?.description || "",
    year: item?.year,
    month: +item?.month,
    monthObj: months.find((m) => m.value === +item?.month),
    isArchived: !!item?.archiveInfo?.archived,
    lists:
      item?.lists?.length > 0
        ? item.lists.map((list) => {
            return {
              variableType: nonAccountingKPIsVariables.list,
              data: list,
            };
          })
        : [],
    variable:
      item?.columns?.length > 0
        ? item.columns.map(
            (column: {
              id: string;
              name: string;
              code: string;
              description: string;
              datatype: Datatype;
              aggregation: Aggregation;
            }) => {
              return {
                variableType: nonAccountingKPIsVariables.variable,
                name: column.name || "",
                code: column.code || "",
                description: column.description || "",
                type: {
                  id: column.datatype?.id || "",
                  code: column.datatype?.code || "",
                  name: column.datatype?.name || "",
                },
                property: column.aggregation?.code
                  ? dataSetVariablePropertyOption.find(
                      (p) => p.value === column.aggregation.code
                    )
                  : null,
              };
            }
          )
        : [],
  };
}

export function dataSetsListItemResponseHelper(item) {
  return {
    id: item?.id || "",
    name: item?.name || "",
    elements:
      item?.elements?.length > 0
        ? item.elements.map((e) => ({ id: uuidV4(), value: e }))
        : [],
    isArchived: !!item?.archiveInfo?.archived,
  };
}

export function dataSetCartesianStateResponseHelper(data) {
  const itemsState = [];
  const haveItems = data?.items?.length > 0;
  const haveVariables = data?.columns?.length > 0;
  return {
    haveItems: haveItems,
    haveVariables: haveVariables,
    variables: data?.columns || [],
    items: data?.items || [],
    itemsState: itemsState,
  };
}

export function customKPIsListResponseHelper(response) {
  return {
    isItems: response?.items?.length > 0,
    items: response?.items || [],
    totalPages: response?.totalPages || 0,
    currentPage: response?.currentPageNumber || 0,
    totalRecords: response?.totalRecords || 0,
  };
}

export function customKPIsItemResponseHelper(
  item: CustomKPI | null | undefined
) {
  return {
    id: item?.id || "",
    name: item?.name || "",
    description: item?.description || "",
  };
}

function isValidDataSourceType(dataSourceType) {
  // Check if dataSourceType is valid before normalizing
  if (typeof dataSourceType !== "string") {
    return false; // Not a valid type
  }

  // Normalize the dataSourceType for consistent comparison
  const normalizedType = dataSourceType.trim().toLowerCase();
  const validTypes = ["parentaccount", "subaccount", "clientdataset"];

  // Return true if the normalized type is found in the valid types
  const isValid = validTypes.includes(normalizedType);

  return isValid;
}

function isKPIInputItemValid(inputItem) {
  // Check if the selected account and its dataSourceType are defined
  const { selectedAccount } = inputItem;
  if (!selectedAccount || !selectedAccount.dataSourceType) {
    return false; // No account or dataSourceType
  }

  // Return true if the input item is a constant with a valid value
  if (isKPIInputItemConstant(inputItem) && !isEmpty(inputItem.value)) {
    return true; // Valid constant input
  }

  // Check for valid data source types without aggregation
  if (isValidDataSourceType(selectedAccount.dataSourceType)) {
    return true; // Invalid aggregation for the specified data source type
  }

  // Ensure that the trailing period has a value
  if (!inputItem.trailingPeriod?.value) {
    return false; // No trailing period value
  }

  // If all checks passed, the input item is valid
  return true;
}

export function getAllValidKPIInputs(inputs: KPIInputItem[] = []) {
  // Filter and return valid KPI input items
  return inputs.filter(isKPIInputItemValid);
}

export function getMatchingBracketIndex(
  index: number,
  input: Array<KPIFormulaItem>
) {
  const isOpeningBracket = input[index].id === bracketItemIds.open;
  if (index < 0 || index >= input.length) {
    throw new Error("Index out of range");
  }

  // -1 will iterate from the end of the array and 1 will iterate from the start
  const direction = isOpeningBracket ? 1 : -1;

  let count = 1;
  const originalBracketId = input[index].id;
  for (let i = index + direction; i >= 0 && i < input.length; i += direction) {
    const currentBracketId = input[i].id;
    const itemIsBracket = input[i].type === formulaItemTypes.bracket;
    if (!itemIsBracket) {
      continue;
    }
    if (originalBracketId === currentBracketId) {
      count++;
    } else {
      count--;

      if (count === 0) {
        return i;
      }
    }
  }

  throw new Error("No matching parenthesis found");
}

export function getKPIFormulaErrors(formula: Array<KPIFormulaItem> = []) {
  if (formula.length === 0) return "No Formula Added";
  const haveOneOpenBracket =
    formula?.[0]?.type === formulaItemTypes.bracket &&
    formula?.[0]?.id === bracketItemIds.open;
  if (!haveOneOpenBracket) return "Minimum one bracket require in formula";
  const haveOneInputs =
    formula.filter((f) => f.type === formulaItemTypes.input).length > 0;
  if (!haveOneInputs) return "Minimum one input require in formula";

  // Check if Two open-close bracket are together
  const haveTwoOpenCloseBracketTogether = formula.some((f, i) => {
    if (f.type === formulaItemTypes.bracket) {
      if (
        f.id === bracketItemIds.open &&
        formula[i + 1]?.type &&
        formula[i + 1]?.type === formulaItemTypes.bracket &&
        formula[i + 1]?.id === bracketItemIds.close
      ) {
        return true;
      }
      if (
        f.id === bracketItemIds.close &&
        formula[i + 1]?.type &&
        formula[i + 1]?.type === formulaItemTypes.bracket &&
        formula[i + 1]?.id === bracketItemIds.open
      ) {
        return true;
      }
    }
    return false;
  });
  if (haveTwoOpenCloseBracketTogether) return "Invalid formula structure";

  // Check if Two Inputs or Two Operators are together
  const haveTwoTypesTogether = formula.some((f, i) => {
    if (f.type === formulaItemTypes.bracket) return false;
    if (formula[i + 1]) {
      return f.type === formula[i + 1].type;
    }
    return false;
  });
  if (haveTwoTypesTogether) return "Invalid formula structure";

  // Check if Operator is not before closing brackets and after open brackets
  const isOperatorUsedIncorrectly = formula.some((f, i) => {
    if (f.type === formulaItemTypes.operator) {
      if (!formula[i - 1]?.type || !formula[i + 1]?.type) {
        return true;
      }
      if (
        formula[i - 1]?.type &&
        formula[i - 1]?.type === formulaItemTypes.bracket &&
        formula[i - 1]?.id === bracketItemIds.open
      ) {
        return true;
      }
      if (
        formula[i + 1]?.type &&
        formula[i + 1]?.type === formulaItemTypes.bracket &&
        formula[i + 1]?.id === bracketItemIds.close
      ) {
        return true;
      }
    }
    return false;
  });
  if (isOperatorUsedIncorrectly) return "Invalid formula structure";

  // Check if Input is not after closing brackets and before open brackets
  const isInputUsedIncorrectly = formula.some((f, i) => {
    if (f.type === formulaItemTypes.input) {
      if (
        formula[i + 1]?.type &&
        formula[i + 1]?.type === formulaItemTypes.bracket &&
        formula[i + 1]?.id === bracketItemIds.open
      ) {
        return true;
      }
      if (
        formula[i - 1]?.type &&
        formula[i - 1].type === formulaItemTypes.bracket &&
        formula[i - 1].id === bracketItemIds.close
      ) {
        return true;
      }
    }
    return false;
  });
  if (isInputUsedIncorrectly) return "Invalid formula structure";
  return "";
}

export function getInputRandomNumber(inputs = []) {
  const testValueArr = [];
  if (inputs.length > 0) {
    inputs.forEach((i) => testValueArr.push(i?.testValue));
  }
  const maxNumber = 10 + inputs.length * 2;
  const minNumber = 2;
  const uniqRandomInteger = getUniqRandomInteger(
    testValueArr,
    maxNumber,
    minNumber
  );
  return uniqRandomInteger;
}

export function getCalculatedFormulaValue(
  formula: Array<KPIFormulaItem> = [],
  inputs: Array<KPIInputItem> = []
) {
  const formulaError = getKPIFormulaErrors(formula);
  if (formulaError) {
    return {
      errorMessage: formulaError,
      value: NaN,
    } as const;
  }
  const parsedFormula: Array<number | string> = [];
  for (const formulaItem of formula) {
    if (formulaItem.type === formulaItemTypes.bracket) {
      const label = bracketItems.find((b) => b.id === formulaItem.id)?.label;
      if (label) parsedFormula.push(label);
      continue;
    }

    if (formulaItem.type === formulaItemTypes.operator) {
      const label = operatorItems.find((o) => o.id === formulaItem.id)?.label;
      if (label) parsedFormula.push(label);
      continue;
    }

    if (formulaItem.type === formulaItemTypes.input) {
      const findInputItem = inputs.find((i) => i.id === formulaItem.id);
      if (isKPIInputItemConstant(findInputItem)) {
        parsedFormula.push(parseInt(findInputItem.value));
      } else {
        if (findInputItem?.testValue) {
          parsedFormula.push(findInputItem.testValue);
        }
      }
    }
  }
  try {
    const value = eval(parsedFormula.join(""));
    // Rounded this way to avoid the 0.5 JS issue
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/round#description
    const precisionRoundedValue = Math.round(value * 100) / 100;

    return {
      value: precisionRoundedValue.toFixed(2),
      errorMessage: "",
    } as const;
  } catch (error) {
    return {
      errorMessage: "Invalid formula structure",
      value: NaN,
    } as const;
  }
}

export function isAccountAvailableOnCOASelection(
  kpiOptions: {
    options: {
      value: string;
    }[];
  }[],
  selectedAccount: {
    value: string;
  }
) {
  let isAvailable = false;
  kpiOptions.map(
    (i: {
      options: {
        value: string;
      }[];
    }) =>
      i.options.map((j) => {
        if (j.value === selectedAccount?.value) {
          isAvailable = true;
        }
      })
  );
  return isAvailable;
}

export function getAccountAvailableOnAllOptions(
  allOptions: {
    options: {
      value: string;
    }[];
  }[],
  selectedAccount: {
    id: string;
  }
) {
  let data = null;
  allOptions.map(
    (i: {
      options: {
        value: string;
      }[];
    }) =>
      i.options.map((j) => {
        if (j.value === selectedAccount?.id) {
          data = j;
        }
      })
  );
  return data;
}
