/* eslint-disable max-lines */
import useObjState from "@hooks/useObjState";
import * as Sentry from "@sentry/nextjs";
import UsersServices from "@services/APIs/Users";
import AuthServices from "@services/AuthServices";
import { AgencyDisplayModel } from "@tgg_accounting/tenant-api";
import { checkUserRights } from "@utils/constant/users";
import { userItemResponseHelper } from "@utils/helper/users";
import { goToDashboard, protectedRoutes, routes } from "@utils/route";
import crypto from "crypto";
import { useRouter } from "next/router";
import React, {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

type AuthContextType = {
  isAuthenticated: boolean;
  setIsAuthenticated: (value: boolean) => void;
  userInfo: {
    firstName: string;
    lastName: string;
    displayName: string;
    email: string;
    imageUrl: string;
    userId: string;
    userRole: string;
    jobRole: string;
    permissions: string[];
    clientId: string;
    assignedClients: any[];
    assignedAgencies: any[];
    roleGroup: string;
    roleGroupCode: string;
    isOwner: boolean;
    isActive: boolean;
    isTGGUser: boolean;
    isSuperAdmin: boolean;
    isClientUser: boolean;
    isFullAccess: boolean;
    isMultiClient: boolean;
    isStandardAccess: boolean;
    isReadOnlyAccess: boolean;
    parentAccountId: string;
    isGlobalUser: boolean;
    agency: AgencyDisplayModel;
  };
  setUserInfo: (value: any) => void;
  isUserInfoLoading: boolean;
  authenticateAndRedirect: (isRedirect?: boolean) => void;
  handleLogout: () => void;
  haveRights: (permissionType: string) => boolean;
};

const AuthContext = createContext<Partial<AuthContextType>>({});

export const useAuth = () => useContext(AuthContext);

const AuthProvider: FC<PropsWithChildren> = (props) => {
  const route = useRouter();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isUserInfoLoading, setIsUserInfoLoading] = useState(false);
  const [userInfo, setUserInfo, _handleUserInfo, clearUserInfo] = useObjState({
    firstName: "",
    lastName: "",
    displayName: "",
    email: "",
    imageUrl: "",
    userId: "",
    userRole: "",
    jobRole: "",
    clientId: "",
    roleGroup: "",
    roleGroupCode: "",
    permissions: [],
    assignedClients: [],
    assignedAgencies: [],
    isOwner: false,
    isActive: false,
    isTGGUser: false,
    isClientUser: false,
    isFullAccess: false,
    isGlobalUser: false,
    isSuperAdmin: false,
    isMultiClient: false,
    isReadOnlyAccess: false,
    isStandardAccess: false,
    agency: null,
    parentAccountId: "",
  });

  // log out after 30 minutes of inactivity.
  useEffect(() => {
    let inactiveTime = 0;
    if (process.browser) {
      document.addEventListener("mousemove", () => {
        inactiveTime = 0;
      });
      document.addEventListener("keypress", () => {
        inactiveTime = 0;
      });
    }
    const inactiveInterval = setInterval(async () => {
      if (inactiveTime >= 1000 * 60 * 30) {
        inactiveTime = 0;
        AuthServices.logOut();
      }
      inactiveTime += 1000;
    }, 1000);

    return () => clearInterval(inactiveInterval);
  }, []);

  async function authenticateAndRedirect(isRedirect?: boolean) {
    const decodedToken = AuthServices.getDecodedAccessToken();
    if (decodedToken && decodedToken?.sub) {
      setIsUserInfoLoading(true);
      const userId = decodedToken.tggUserId;
      const userDetails = await UsersServices.getUserById({
        queryKey: [``, { userId: userId, include: "assigned-agency" }],
      });

      const {
        id,
        userRole,
        permissions,
        firstName,
        lastName,
        email,
        jobRole,
        imageUrl,
        client,
        createdAt,
        roleGroup,
        roleGroupCode,
        assignedClients,
        assignedAgencies,
        isOwner,
        isActive,
        isTGGUser,
        isSuperAdmin,
        isClientUser,
        isFullAccess,
        isGlobalUser,
        isMultiClient,
        isReadOnlyAccess,
        isStandardAccess,
        clientName,
        agency,
        parentAccountId,
      } = userItemResponseHelper(userDetails);
      if (id && isActive && userRole) {
        const secret = process.env.NEXT_PUBLIC_CHAMELEON_SECRET;
        const now = Math.floor(Date.now() / 1000);
        const uid_hash = [
          crypto
            .createHmac("sha256", secret)
            .update(`${id}-${now}`)
            .digest("hex"),
          now,
        ].join("-");
        const chmlnProfile = {
          uid_hash: uid_hash,
          email: email,
          name: `${firstName} ${lastName}`,
          created: createdAt,
          role: userRole,
          permission: roleGroupCode,
        };
        setIsAuthenticated(true);
        setUserInfo({
          firstName: firstName,
          lastName: lastName,
          displayName: `${firstName} ${lastName}`,
          email: email,
          imageUrl: imageUrl,
          userId: userId,
          userRole: userRole,
          jobRole: jobRole,
          isSuperAdmin: isSuperAdmin,
          isTGGUser: isTGGUser,
          isClientUser: isClientUser,
          isActive: isActive,
          permissions: permissions,
          clientId: isClientUser ? client.id : "",
          assignedClients: assignedClients,
          assignedAgencies: assignedAgencies,
          roleGroup: roleGroup,
          roleGroupCode: roleGroupCode,
          isOwner: isOwner,
          isFullAccess: isFullAccess,
          isMultiClient: isMultiClient,
          isStandardAccess: isStandardAccess,
          isReadOnlyAccess: isReadOnlyAccess,
          isGlobalUser: isGlobalUser,
          agency: agency,
          parentAccountId: parentAccountId,
        });

        Sentry.setUser({
          id: userId,
          email,
          username: `${firstName} ${lastName}`,
        });

        if (agency && parentAccountId && (isClientUser || isTGGUser)) {
          const companyProfile = {
            uid: parentAccountId,
            name: agency?.name || "",
            clientName: clientName || "",
          };
          chmlnProfile.logoUrl = agency?.logoUrl || "";
          window?.chmln?.identify(id, {
            ...chmlnProfile,
            company: { ...companyProfile },
          });
        } else {
          window?.chmln.identify(id, { ...chmlnProfile });
        }
        setIsUserInfoLoading(false);
        if (isRedirect) {
          if (parentAccountId && isClientUser && !isMultiClient) {
            route.push({
              pathname: routes.client.clientUserDashboard,
              query: {
                agencySlug: agency.urlSlug,
                clientSlug: client.urlSlug,
              },
            });
          } else if (isTGGUser && !!parentAccountId) {
            route.push({
              pathname: routes.admin.client.list,
              query: {
                agencySlug: agency.urlSlug,
              },
            });
          } else {
            route.push({
              pathname: goToDashboard(isClientUser, isMultiClient),
              query:
                isClientUser || isMultiClient
                  ? {
                      agencySlug: agency.urlSlug,
                      clientSlug: client.urlSlug,
                    }
                  : {},
            });
          }
        }
      } else {
        AuthServices.logOut();
      }
    } else {
      AuthServices.logOut();
    }
  }

  async function checkAuthentication() {
    const resp = AuthServices.handleAuthentication();
    if (resp === "notValidToken") {
      handleLogout();
    } else if (resp === "Authenticated") {
      authenticateAndRedirect();
    }
  }

  function handleLogout() {
    setIsAuthenticated(false);
    clearUserInfo();
    AuthServices.logOut();
  }

  function haveRights(permissionType) {
    return checkUserRights(permissionType, isAuthenticated, userInfo);
  }

  // protect route, need to change after getting role of user
  useEffect(() => {
    const isProtected = protectedRoutes.some((p) => p.path === route.pathname);
    if (isProtected) {
      const resp = AuthServices.handleAuthentication();
      if (resp !== "Authenticated") {
        route.push(routes.common.login);
      }
    }
  }, [route]);

  // check by interval
  useEffect(() => {
    const interval = setInterval(async () => {
      const isProtected = protectedRoutes.some(
        (p) => p.path === route.pathname
      );
      if (isProtected) {
        const resp = AuthServices.handleAuthentication();
        if (resp === "noAccessToken") {
          route.push(routes.common.login);
        } else if (resp === "notValidToken") {
          handleLogout();
        }
      }
    }, 60000);
    return () => clearInterval(interval);
  }, [route]);

  // check by interval and notify 5 min before token expire
  useEffect(() => {
    const interval = setInterval(async () => {
      const isAboutToExpire = AuthServices.isAboutToExpire();
      if (isAboutToExpire) {
        if (
          confirm(
            "Your session will expire in 5 minutes. Please re-login to continue using this application."
          )
        ) {
          AuthServices.logOut();
        }
      }
    }, 30000);
    return () => clearInterval(interval);
  }, []);

  // check after load
  useEffect(() => {
    checkAuthentication();
  }, []);

  const authValue = useMemo(
    () => ({
      isAuthenticated,
      setIsAuthenticated,
      userInfo,
      setUserInfo,
      isUserInfoLoading,
      authenticateAndRedirect,
      handleLogout,
      haveRights,
    }),
    [isAuthenticated, userInfo, isUserInfoLoading]
  );

  return (
    <AuthContext.Provider value={authValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
