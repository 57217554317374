/* eslint-disable max-lines */
import { tggClient } from "@services/AxiosConfig";
import { QueryFunctionContext } from "react-query";

// Define interfaces for the expected response data
interface AuditInfo {
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
}

interface ActiveInfo {
  active: boolean;
  activeStatusChangedAt: string;
}

interface ArchiveInfo {
  archived: boolean;
  archivedStatusChangedAt: string;
}

interface Category {
  id: string;
  name: string;
  description: string;
  auditInfo: AuditInfo;
  kpis: any[];
}

interface Variable {
  type: string;
  id: string;
  source: string;
  name: string;
  aggregation: string;
  trailingPeriod: {
    type: string;
    valueType: string;
    value: string;
  };
}

interface Metadata {
  formula: string;
  variables: Variable[];
  outputVariable: {
    dataType: string;
    formatting: {
      decimalFields: number;
    };
  };
}

export interface StandardKPI {
  id: string;
  name: string;
  description: string;
  addedToReport: boolean;
  auditInfo: AuditInfo;
  activeInfo: ActiveInfo;
  archiveInfo: ArchiveInfo;
  category: Category;
  metadata: Metadata;
}

interface KPIInputData {
  items: Array<{
    groupName: string;
    dataSourceType: string;
    items: Array<{
      id: string;
      name: string;
      reportType: {
        code: string;
      };
      outputFormat: {
        dataType: string;
        decimalFields: number;
      };
      aggregation: {
        aggregationType: string;
      };
    }>;
    inputs: Array<{
      groupName: string;
      dataSourceType: string;
      items: Array<{
        id: string;
        name: string;
        reportType: {
          code: string;
        };
        outputFormat: {
          dataType: string;
          decimalFields: number;
        };
        elements: { items: Array<unknown> };
        aggregation: {
          aggregationType: string;
        };
      }>;
    }>;
  }>;
}

export interface CustomKPI {
  id: string;
  name: string;
  description: string;
  addedToReport: boolean;
  auditInfo: AuditInfo;
  activeInfo: ActiveInfo;
  archiveInfo: ArchiveInfo;
  category: Category;
  metadata: Metadata;
}

class KPIsServices {
  // GET ALL STD KPIs
  getAllKPIs = async ({
    queryKey,
  }: QueryFunctionContext<
    [
      string,
      {
        include: string;
        sortDir: string;
        sortBy: string;
        take: number;
        skip: number;
        searchTerm: string;
        categoryId: string;
        active: boolean;
        clientId: string;
        archive: boolean | string;
        mode: string;
        year: string;
        month: string;
      }
    ]
  >) => {
    const [
      _key,
      {
        include = "",
        sortDir = "ASC",
        sortBy = "",
        take = 10,
        skip = 0,
        searchTerm = "",
        categoryId = "",
        active = true,
        archive = false,
        mode = "",
        year = "",
        month = "",
      },
    ] = queryKey;
    try {
      const response = await tggClient.get<never, { items: StandardKPI[] }>(
        `/kpis/standard-kpis`,
        {
          params: {
            include,
            sortDir,
            sortBy,
            take,
            skip,
            searchTerm,
            categoryId,
            active,
            archive,
            mode,
            year,
            month,
          },
        }
      );
      return response;
    } catch (error) {
      console.warn("Client Id not provided in KPIsServices.getAllKPIs", error);
      return null;
    }
  };

  // GET KPIs CATEGORY
  getKPIsCategory = async ({
    queryKey,
  }: {
    queryKey: [
      string,
      {
        include: string;
        sortBy: string;
        sortDir: string;
        take: number;
        skip: number;
      }
    ];
  }) => {
    const [
      _key,
      { include = "", sortBy = "", sortDir = "ASC", take = 10, skip = 0 },
    ] = queryKey;

    const response = await tggClient.get<never, { items: Category[] }>(
      `/kpis/standard-kpi-categories`,
      {
        params: { include, sortBy, sortDir, take, skip },
      }
    );
    return response;
  };

  // GET Std KPI Details by Id
  getStdKPIDetailsById = async ({
    queryKey,
  }: QueryFunctionContext<[string, { kpiId: string }]>) => {
    const [_key, { kpiId }] = queryKey;
    if (kpiId) {
      const response = await tggClient.get<never, StandardKPI>(
        `/kpis/standard-kpis/${kpiId}`
      );
      return response;
    }
    console.warn("kpiId is not provided, KPIsServices.getStdKPIDetailsById");
    return null;
  };

  getKPIInputData = async ({
    queryKey,
  }: {
    queryKey: [string, { clientId: string; type: string }];
  }) => {
    const [_key, { clientId = "", type }] = queryKey;
    const response = await tggClient.get<never, KPIInputData>(
      `/kpis/input-data`,
      {
        params: { clientId, type },
      }
    );
    return response;
  };

  createCustomKPI = async (clientId: string, data: { refKpiId: string }) => {
    if (clientId && data) {
      const response = await tggClient.post<never, CustomKPI>(
        `/clients/${clientId}/custom-kpi`,
        data
      );
      return response;
    }
    console.warn(
      "Client Id or data not provided in KPIsServices.createCustomKPI"
    );
    return null;
  };

  getCustomKPIs = async ({
    queryKey,
  }: QueryFunctionContext<
    [
      string,
      {
        clientId: string;
        sortDir: string;
        sortBy: string;
        take: number;
        skip: number;
        searchTerm: string;
        active: boolean;
        pagination: boolean;
        mode: string;
        year: string;
        month: string;
      }
    ]
  >) => {
    const [
      _key,
      {
        clientId,
        sortDir = "ASC",
        sortBy = "",
        take = 10,
        skip = 0,
        searchTerm = "",
        active = true,
        pagination = true,
        mode = "",
        year = "",
        month = "",
      },
    ] = queryKey;
    if (clientId) {
      const response = await tggClient.get<
        never,
        {
          totalPages: number;
          currentPageNumber: number;
          totalRecords: number;
          items: CustomKPI[];
        }
      >(`/clients/${clientId}/custom-kpi`, {
        params: {
          sortDir,
          sortBy,
          take,
          skip,
          searchTerm,
          active,
          pagination,
          mode,
          year,
          month,
        },
      });
      return response;
    }
    console.warn("Client Id not provided in KPIsServices.getCustomKPIs");
    return null;
  };

  getCustomKPIDetails = async ({
    queryKey,
  }: QueryFunctionContext<[string, { clientId: string; kpiId: string }]>) => {
    const [_key, { clientId, kpiId }] = queryKey;
    if (clientId && kpiId) {
      const response = await tggClient.get<never, CustomKPI>(
        `/clients/${clientId}/custom-kpi/${kpiId}`
      );
      return response;
    }
    console.warn(
      "Client Id or kpi Id not provided in KPIsServices.getCustomKPIDetails"
    );
    return null;
  };

  updateCustomKPIDetails = async (
    clientId: string,
    kpiId: string,
    data: {
      newData: {
        name: string;
        description: string;
        metadata: string;
      };
      oldData: {
        name: string;
        description: string;
        metadata: string;
      };
    }
  ) => {
    if (clientId && kpiId) {
      const response = await tggClient.patch<never, CustomKPI>(
        `/clients/${clientId}/custom-kpi/${kpiId}`,
        data
      );
      return response;
    }
    console.warn(
      "Client Id or kpi Id not provided in KPIsServices.updateCustomKPIDetails"
    );
    return null;
  };

  archiveKPI = async (kpiId: string) => {
    if (kpiId) {
      const response = await tggClient.post<never, { value: boolean }>(
        `/kpis/standard-kpis/${kpiId}/archive`
      );
      return response;
    }
    console.warn("kpiId not provided in KPIsServices.archiveKPI");
    return null;
  };

  restoreKPI = async (kpiId: string) => {
    if (kpiId) {
      const response = await tggClient.post<never, { value: boolean }>(
        `/kpis/standard-kpis/${kpiId}/restore`
      );
      return response;
    }
    console.warn("kpiId not provided in KPIsServices.restoreKPI");
    return null;
  };
}

const instance = new KPIsServices();

export default instance;
