import { useAuth } from "@context/auth";
import { useClient } from "@context/client";
import AgencyServices from "@services/APIs/Agency";
import { ClientDisplayModel } from "@tgg_accounting/tenant-api";
import { clientCookieManager } from "@utils/cookies/client";
import { routes } from "@utils/route";
import { useRouter } from "next/router";
import React, {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useQuery } from "react-query";

const AccountContext = createContext({
  isRoleAssumed: false,
  accountId: "",
  agencySlug: "",
  accountDetails: null as ClientDisplayModel | null,
  assumeRole: (_id: string, _isRedirect: boolean) => {},
  removeRole: () => {},
  refetchAccountDetails: () => {},
});

export const useAccount = () => useContext(AccountContext);

const AccountProvider: FC<PropsWithChildren> = (props) => {
  const route = useRouter();
  const { userInfo } = useAuth();
  const {
    isClientUser,
    isTGGUser,

    agency,
  } = userInfo ?? {};

  const { isRoleAssumed: isClientRoleAssumed, removeRole: removeClientRole } =
    useClient();
  const [isRoleAssumed, setIsRoleAssumed] = useState<boolean>(false);
  const [accountId, setAccountId] = useState<string>("");
  const [agencySlug, setAgencySlug] = useState<string>("");
  const [accountDetails, setAccountDetails] =
    useState<ClientDisplayModel | null>(null);

  const {
    data: client,
    isSuccess,
    isLoading,
    refetch: refetchAccountDetails,
  } = useQuery(
    [
      `/agencies/${accountId}`,
      {
        agencyId: accountId,
        include: ["agency"],
      },
    ],
    AgencyServices.getAgencyById,
    {
      refetchOnWindowFocus: false,
      enabled: !!accountId && !userInfo?.isClientUser && !userInfo?.isTGGUser,
    }
  );

  useEffect(() => {
    if (accountId !== accountDetails?.id) {
      setAccountDetails(null);
    }
  }, [accountId, accountDetails]);

  useEffect(() => {
    if (client && isSuccess) {
      setAccountDetails(client);
    }
  }, [isLoading, client]);

  useEffect(() => {
    if (agency && (isTGGUser || isClientUser)) {
      setAccountDetails(agency);
    }
  }, [agency, accountId]);

  const assumeRole = useCallback(
    async (id: string, isRedirect: boolean) => {
      setAccountId(id);
      clientCookieManager.setCookie("hub.agency.id", id);
      if (isRedirect) {
        route.push({
          pathname: routes.admin.client.list,
          query: {
            agencySlug,
          },
        });
      }
    },
    [route, setAccountId]
  );

  const removeRole = useCallback(() => {
    if (isClientRoleAssumed) {
      removeClientRole();
    }
    setAccountId("");
    setAgencySlug("");
    setIsRoleAssumed(false);
    clientCookieManager.deleteCookie("hub.agency.id");
    clientCookieManager.deleteCookie("hub.agency.slug");
    route.push(routes.admin.agency.list);
  }, [route, removeClientRole, isClientRoleAssumed]);

  useEffect(() => {
    const cookieAgencyId = clientCookieManager.getCookie("hub.agency.id");
    if (cookieAgencyId) {
      setAccountId(cookieAgencyId);
      setIsRoleAssumed(true);
    } else {
      setAccountId("");
      setIsRoleAssumed(false);
    }
    const cookieAgencySlug = clientCookieManager.getCookie("hub.agency.slug");
    setAgencySlug(cookieAgencySlug);
  }, [route.pathname]);

  return (
    <AccountContext.Provider
      value={{
        isRoleAssumed,
        accountId,
        agencySlug,
        assumeRole,
        removeRole,
        accountDetails,
        refetchAccountDetails,
      }}
    >
      {props.children}
    </AccountContext.Provider>
  );
};

export default AccountProvider;
