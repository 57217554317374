import { tggClient, tggTenantClient } from "@services/AxiosConfig";
import {
  ParentAccountDisplayModel,
  SubAccountDisplayModel,
} from "@tgg_accounting/hub-node-api/dist/models";
import {
  AgencyInfoDisplayModel,
  UserAgencyDetailsDisplayModel,
} from "@tgg_accounting/tenant-api";
import { ActiveInfo, AuditInfo } from "@utils/types/global";

interface ReportType {
  id: string;
  name: string;
  description: string;
  code: string;
  activeInfo: ActiveInfo;
  auditInfo: AuditInfo;
}

interface GetSubaccountsByParentResponse {
  items: SubAccountDisplayModel[];
}

interface ParentAccountResponse {
  items: ParentAccountDisplayModel[];
}

interface BoolResult {
  value: boolean;
}

interface QBAccountDetail {
  accountType: string;
  detailType: string;
  reportTypeId: string;
  parentAccountId: string;
}

interface QBAccountDetailsResponse {
  items: QBAccountDetail[];
}

interface BalanceSheetAccount {
  id: string;
  name: string;
  akaNames: string[];
  reportType: ReportType;
  subAccounts: (SubAccountDisplayModel | null)[];
  displayOrder: string;
}

interface BalanceSheetAccountResponse {
  items: BalanceSheetAccount[];
}

interface IGetSubAccounts {
  parentAccountId: string;
  include: string;
}

interface IGetParentAccounts {
  clientId: string;
  include: string;
  searchTerm: string;
  active: boolean | null;
  reportTypeId: string;
  isNetConsolidate: boolean;
  includeNetConsolidate: string[];
}

interface IGetQBAccountDetails {
  reportTypeId: string;
  parentAccountId: string;
}

interface IGetCashFlowStatements {
  clientId: string;
}

interface IGetBalanceSheetAccounts {
  clientId: string;
}

interface IGetAgencyDetails {
  slug: string;
}

interface IGetAgencySlugByEmail {
  email: string;
}

class AccountsServices {
  // GET SUB ACCOUNTS
  getSubAccounts = async ({
    queryKey,
  }: {
    queryKey: [string, IGetSubAccounts];
  }) => {
    const [_key, { parentAccountId, include = "" }] = queryKey;
    if (parentAccountId) {
      return await tggClient.get<never, GetSubaccountsByParentResponse>(
        `/parent-accounts/${parentAccountId}/sub-accounts`,
        { params: { include } }
      );
    }

    console.warn(
      "parentAccountId not provided, AccountsServices.getSubAccounts"
    );
    return null;
  };

  // GET ALL PARENT ACCOUNTS
  getParentAccounts = async ({
    queryKey,
  }: {
    queryKey: [string, IGetParentAccounts];
  }) => {
    const [
      _key,
      {
        clientId = "",
        include = "",
        searchTerm = "",
        active = null,
        reportTypeId = "",
        isNetConsolidate = false,
        includeNetConsolidate = [],
      },
    ] = queryKey;
    const params = {
      reportTypeId,
      clientId,
      include,
      searchTerm,
      active,
      isNetConsolidate,
      includeNetConsolidate,
    };
    return await tggClient.get<never, ParentAccountResponse>(
      `/parent-accounts`,
      {
        params,
      }
    );
  };

  // ACTIVATE PARENT ACCOUNT
  activateParentAccount = async (parentAccountId: string) => {
    if (parentAccountId) {
      return await tggClient.post<BoolResult>(
        `/parent-accounts/${parentAccountId}/activate`
      );
    }
    console.warn(
      "parentAccountId not provided, AccountsServices.activateParentAccount"
    );
    return { value: false };
  };

  // DEACTIVATE PARENT ACCOUNT
  deactivateParentAccount = async (parentAccountId: string) => {
    if (parentAccountId) {
      return await tggClient.post<never, BoolResult>(
        `/parent-accounts/${parentAccountId}/deactivate`
      );
    }
    console.warn(
      "parentAccountId not provided, AccountsServices.deactivateParentAccount"
    );
    return { value: false };
  };

  // ACTIVATE SUB ACCOUNT
  activateSubAccount = async (subAccountId: string) => {
    if (subAccountId) {
      return await tggClient.post<never, BoolResult>(
        `/sub-accounts/${subAccountId}/activate`
      );
    }
    console.warn(
      "subAccountId not provided, AccountsServices.activateSubAccount"
    );
    return { value: false };
  };

  // DEACTIVATE SUB ACCOUNT
  deactivateSubAccount = async (subAccountId: string) => {
    if (subAccountId) {
      return await tggClient.post<never, BoolResult>(
        `/sub-accounts/${subAccountId}/deactivate`
      );
    }
    console.warn(
      "subAccountId not provided, AccountsServices.deactivateSubAccount"
    );
    return { value: false };
  };

  getQBAccountDetails = async ({
    queryKey,
  }: {
    queryKey: [string, IGetQBAccountDetails];
  }) => {
    const [_key, { reportTypeId, parentAccountId }] = queryKey;
    return await tggClient.get<never, QBAccountDetailsResponse>(
      `/qb-account-details`,
      {
        params: {
          reportTypeId,
          parentAccountId,
        },
      }
    );
  };

  getCashFlowStatements = async ({
    queryKey,
  }: {
    queryKey: [string, IGetCashFlowStatements];
  }) => {
    const [_key, { clientId }] = queryKey;
    return await tggClient.get<never, IGetCashFlowStatements[]>(
      `/clients/${clientId}/reports/cash-flow-mappings`
    );
  };

  getBalanceSheetAccounts = async ({
    queryKey,
  }: {
    queryKey: [string, IGetBalanceSheetAccounts];
  }) => {
    const [_key, { clientId }] = queryKey;
    return await tggClient.get<never, BalanceSheetAccountResponse>(
      `/client/${clientId}/balancesheet-preview`
    );
  };

  getAgencyDetails = async ({
    queryKey,
  }: {
    queryKey: [string, IGetAgencyDetails];
  }) => {
    const [_key, { slug }] = queryKey;
    if (slug) {
      return await tggTenantClient.get<never, AgencyInfoDisplayModel>(
        `/agencies/slug/${slug}`
      );
    }
    return null;
  };

  getAgencySlugByEmail = async ({
    queryKey,
  }: {
    queryKey: [string, IGetAgencySlugByEmail];
  }) => {
    const [_key, { email }] = queryKey;
    if (email) {
      return await tggTenantClient.get<never, UserAgencyDetailsDisplayModel>(
        `/users/user-agency-details`,
        {
          params: { email },
        }
      );
    }
    return null;
  };

  delay = (ms: number): Promise<void> =>
    new Promise((res) => setTimeout(res, ms));
}

const instance = new AccountsServices();

export default instance;
