import { useAccount } from "@context/account";
import { useAuth } from "@context/auth";
import { useClientAISProfile } from "@hooks/apis/useClientAISProfile";
import { useClientPreConditions } from "@hooks/apis/useClientPreConditions";
import useObjState from "@hooks/useObjState";
import ClientsServices from "@services/APIs/Clients";
import { ClientDisplayModel } from "@tgg_accounting/tenant-api";
import { localKeys } from "@utils/constant";
import { clientCookieManager } from "@utils/cookies/client";
import { getQueryString } from "@utils/functions/common";
import { clientConditionResponseHelper } from "@utils/helper/reports/reportConditions";
import { routes } from "@utils/route";
import { useRouter } from "next/router";
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useQuery } from "react-query";
import { useAppDispatch } from "store/hooks";
import { setSidebarVersion, SidebarVersion } from "store/reducers/sidebar";
import { z } from "zod";

type PortalView = "admin" | "client";
const ClientContext = createContext({
  isRoleAssumed: false,
  clientId: "",
  clientSlug: "",
  assumeRole: (_id: string, _isRedirect?: boolean, _aId?: string) => {},
  removeRole: () => {},
  clientDetails: null as ClientDisplayModel | null,
  refetchClientDetails: () => {},
  isOnClientPage: false,
  clientAISProfile: null,
  clientStatus: {
    isAISSetupDone: false,
    isCOASetupDone: false,
    isAISAccountsDownloaded: false,
    isAISMappingDone: false,
    isReportTemplateSetupDone: false,
  },
  refetchClientStatus: () => {},
  isAISAccountSetupPending: false,
  isAISAccountSetupDone: false,
  refetchAISProfileDetails: () => {},
  portalView: "admin" as PortalView,
  setPortalView: (_value: PortalView) => {},
  isClientView: false,
  clientUserAssumeRole: (_id: string, _aId: string) => {},
});

// TODO: Client redux slice.
export const useClient = () => useContext(ClientContext);

const ClientProvider = (props: PropsWithChildren) => {
  const route = useRouter();
  const { userInfo } = useAuth();
  const { agencySlug, isRoleAssumed: isAccountRoleAssumed } = useAccount();
  const [isRoleAssumed, setIsRoleAssumed] = useState(false);
  const [clientId, setClientId] = useState("");
  const [clientSlug, setClientSlug] = useState("");
  const [clientDetails, setClientDetails] = useState<ClientDisplayModel | null>(
    null
  );
  const [isOnClientPage, setIsOnClientPage] = useState(false);
  const [clientAISProfile, setClientAISProfile] = useState(null);
  const [
    clientStatus,
    setClientStatus,
    _handleClientStatus,
    clearClientStatus,
  ] = useObjState({
    isAISSetupDone: false,
    isCOASetupDone: false,
    isAISAccountsDownloaded: false,
    isAISMappingDone: false,
    isReportTemplateSetupDone: false,
  });

  const [portalView, _setPortalView] = useState<PortalView>("admin");
  const { aisProfileDetails, refetchAISProfileDetails } =
    useClientAISProfile(clientId);
  const { data: clientConditionsDetails, refetch: refetchClientStatus } =
    useClientPreConditions(clientId);

  useEffect(() => {
    const cachedPortalView = z
      .enum(["client", "admin"])
      .safeParse(sessionStorage.getItem(localKeys.clientPortalView));
    if (cachedPortalView.success) {
      _setPortalView(cachedPortalView.data);
    }
  }, []);

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (userInfo?.isClientUser && !clientId) {
      dispatch(setSidebarVersion(SidebarVersion.user));
    } else {
      if (userInfo?.isClientUser || isRoleAssumed) {
        dispatch(setSidebarVersion(SidebarVersion.client));
      } else if (isAccountRoleAssumed) {
        dispatch(setSidebarVersion(SidebarVersion.admin));
      } else {
        dispatch(setSidebarVersion(SidebarVersion.owner));
      }
    }
  }, [
    dispatch,
    userInfo?.isClientUser,
    isRoleAssumed,
    isAccountRoleAssumed,
    clientId,
  ]);

  const {
    data,
    isSuccess,
    isLoading,
    refetch: refetchClientDetails,
  } = useQuery(
    [
      `/clients/${clientId}`,
      {
        clientId: clientId,
        include: ["financial-profile", "agency"],
      },
    ],
    ClientsServices.getClientById,
    {
      refetchOnWindowFocus: false,
      enabled: !!clientId,
    }
  );

  const setPortalView = (value: PortalView) => {
    _setPortalView(value);
    sessionStorage.setItem(localKeys.clientPortalView, value);
  };

  function assumeRole(id: string, isRedirect?: boolean) {
    setClientId(id);
    setPortalView("admin");
    clientCookieManager.setCookie("hub.client.id", id);
    if (isRedirect) {
      const qs = getQueryString({
        agencySlug,
        clientSlug,
      });
      route.push({
        pathname: routes.client.details,
        query: qs,
      });
    }
  }

  function clientUserAssumeRole(id: string) {
    setClientId(id);
    clientCookieManager.setCookie("hub.client.id", id);

    route.push({
      pathname: routes.client.clientUserDashboard,
      query: {
        agencySlug,
        clientSlug,
      },
    });
  }

  function removeRole() {
    setPortalView("admin");
    clientCookieManager.deleteCookie("hub.client.id");
    clientCookieManager.deleteCookie("hub.client.slug");
    sessionStorage.removeItem(localKeys.clientPortalView);
    setClientId("");
    setClientSlug("");
  }

  useEffect(() => {
    const cookieClientId = clientCookieManager.getCookie("hub.client.id");
    if (cookieClientId) {
      setClientId(cookieClientId);
      setIsRoleAssumed(true);
    } else {
      setClientId("");
      setIsRoleAssumed(false);
    }
    const cookieClientSlug = clientCookieManager.getCookie("hub.client.slug");
    setClientSlug(cookieClientSlug);
  }, [route.pathname]);

  useEffect(() => {
    if (clientId !== clientDetails?.id) {
      setClientDetails(null);
    }
  }, [clientId, clientDetails]);

  useEffect(() => {
    if (data && isSuccess) {
      setClientDetails(data);
    }
  }, [isLoading, data]);

  useEffect(() => {
    if (route.pathname) {
      if (
        Object.keys(routes.client).some(
          // @ts-ignore
          (r) => routes.client[r] === route.pathname
        )
      ) {
        setIsOnClientPage(true);
      } else {
        setIsOnClientPage(false);
      }
    }
  }, [route]);

  useEffect(() => {
    if (clientId && aisProfileDetails) {
      // @ts-ignore
      setClientAISProfile(aisProfileDetails);
    } else if (clientId && clientAISProfile === null) {
      refetchAISProfileDetails();
    }
  }, [clientId, aisProfileDetails]);

  useEffect(() => {
    if (clientId && clientConditionsDetails) {
      setClientStatus(clientConditionResponseHelper(clientConditionsDetails));
    } else {
      clearClientStatus();
    }
  }, [clientId, clientConditionsDetails]);

  useEffect(() => {
    if (userInfo?.isClientUser) {
      setPortalView("client");
    } else if (
      userInfo?.isSuperAdmin ||
      userInfo?.isTGGUser ||
      userInfo?.isGlobalUser ||
      isRoleAssumed
    ) {
      setPortalView("admin");
    }
  }, [userInfo, isRoleAssumed]);

  const value = useMemo(
    () => ({
      isRoleAssumed,
      clientId,
      clientSlug,
      assumeRole,
      removeRole,
      clientDetails,
      refetchClientDetails,
      isOnClientPage,
      clientAISProfile,
      clientStatus,
      refetchClientStatus,
      isAISAccountSetupPending: !!clientAISProfile || clientAISProfile === null,
      // @ts-ignore
      isAISAccountSetupDone: !!clientAISProfile && !!clientAISProfile?.data?.id,
      refetchAISProfileDetails,
      portalView,
      setPortalView,
      isClientView: userInfo?.isClientUser || portalView === "client",
      clientUserAssumeRole,
    }),
    [
      isRoleAssumed,
      clientId,
      clientSlug,
      clientDetails,
      isOnClientPage,
      clientAISProfile,
      clientStatus,
      portalView,
      userInfo?.isClientUser,
    ]
  );
  return (
    <ClientContext.Provider value={value}>
      {props.children}
    </ClientContext.Provider>
  );
};

export default ClientProvider;
