/* eslint-disable max-lines */
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { defaulTggClient as tggClient } from "@services/AxiosConfig";
import { ParentAccountDisplayModel } from "@tgg_accounting/hub-node-api/dist/models";
import { AxiosError } from "axios";

type Budget = {
  id: string;
  name: string;
  aisId: string;
  type: string;
  startDate: string;
  endDate: string;
  lastModifiedAt: string;
  createdAt: string;
  updatedAt: string;
};

export type BudgetVsActuals = {
  budgetInfo: Budget;
  budgetVsActuals: ParentAccountDisplayModel[];
};

export enum BvAQBOStatus {
  Loading,
  EmptyState,
  ImportingBudget,
  SelectingBudget,
}

const initialState = {
  status: BvAQBOStatus.Loading,
  importBudgetModal: {
    isOpen: false,
    isFetchingBudgets: false,
    availableBudgets: [] as Array<{ label: string; value: string }>,
    error: false,
  },
  selectBudgetScreen: {
    loading: true,
    isRefreshingBudgets: false,
    importedBudgets: [] as Array<Budget>,
    selectedBudgets: {} as Record<string, boolean>,
  },
  viewSelectedBudgetScreen: {
    data: null as BudgetVsActuals | null,
    isLoading: true,
  },
};

export const fetchQuickbooksBudgets = createAsyncThunk(
  "qboBudgets/fetchQuickbooksBudgets",
  async (params: { clientId: string }, { rejectWithValue }) => {
    try {
      const reponse = await tggClient.get<
        never,
        Array<{ id: string; name: string }>
      >(`/clients/${params.clientId}/budgets/qbo/available`);
      return reponse;
    } catch (error) {
      if (error instanceof AxiosError && error?.response?.data) {
        return rejectWithValue(error?.response?.data);
      }
      throw error;
    }
  }
);

export const importBudget = createAsyncThunk(
  "qboBudgets/importBudget",
  async (params: { clientId: string; budgetId: string }) => {
    return tggClient.post<never, Budget, { budgetId: string }>(
      `/clients/${params.clientId}/budgets/qbo/import`,
      {
        budgetId: params.budgetId,
      }
    );
  }
);

export const fetchImportedBudgets = createAsyncThunk(
  "qboBudgets/fetchImportedBudgets",
  async (params: { clientId: string }) => {
    return tggClient.get<
      never,
      {
        items: Array<Budget>;
        currentPageNumber: number;
        recordsPerPage: number;
        totalRecords: number;
        totalPages: number;
      }
    >(`/clients/${params.clientId}/budgets/qbo`, {
      params: {
        skip: 0,
        take: 1000,
      },
    });
  }
);

export const refreshSelectedBudgets = createAsyncThunk(
  "qboBudgets/refreshSelectedBudgets",
  async (params: { clientId: string }, thunkApi) => {
    const state = thunkApi.getState() as { qboBudgets: typeof initialState };
    const budgetIds = Object.keys(
      state.qboBudgets.selectBudgetScreen.selectedBudgets
    );
    const refreshedBudgets = await Promise.all(
      budgetIds.map((budgetId) =>
        tggClient.post<never, Budget>(
          `/clients/${params.clientId}/budgets/qbo/${budgetId}/refresh`
        )
      )
    );
    return refreshedBudgets.reduce(
      (acc, budget) => ({
        ...acc,
        [budget.id]: budget,
      }),
      {} as Record<string, Budget>
    );
  }
);

export const deleteBudget = createAsyncThunk(
  "qboBudgets/deleteBudget",
  async (params: { clientId: string; budgetId: string }) => {
    return tggClient.delete<never, { value: boolean }>(
      `/clients/${params.clientId}/budgets/qbo/${params.budgetId}`
    );
  }
);

export const getBudgetVsActuals = createAsyncThunk(
  "qboBudgets/getBudgetVsActuals",
  async (params: { clientId: string; budgetId: string }) => {
    return tggClient.get<never, BudgetVsActuals>(
      `/clients/${params.clientId}/budgets/qbo/${params.budgetId}`
    );
  }
);

export const qboBudgetsSlice = createSlice({
  name: "qboBudgets",
  initialState,
  reducers: {
    setShowBudgetSelectorModal: (state, action: PayloadAction<boolean>) => {
      state.importBudgetModal.isOpen = action.payload;
    },
    selectAllBudgets: (state) => {
      for (const budget of state.selectBudgetScreen.importedBudgets) {
        state.selectBudgetScreen.selectedBudgets[budget.id] = true;
      }
    },
    clearAllSelectedBudgets: (state) => {
      state.selectBudgetScreen.selectedBudgets = {};
    },
    toggleBudgetSelection: (state, action: PayloadAction<string>) => {
      if (state.selectBudgetScreen.selectedBudgets[action.payload]) {
        delete state.selectBudgetScreen.selectedBudgets[action.payload];
      } else {
        state.selectBudgetScreen.selectedBudgets[action.payload] = true;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(importBudget.pending, (state) => {
      state.status = BvAQBOStatus.ImportingBudget;
    });
    builder.addCase(importBudget.fulfilled, (state, action) => {
      state.selectBudgetScreen.importedBudgets.push(action.payload);
      state.status = BvAQBOStatus.SelectingBudget;
    });
    builder.addCase(fetchQuickbooksBudgets.pending, (state) => {
      state.importBudgetModal.isFetchingBudgets = true;
    });
    builder.addCase(fetchQuickbooksBudgets.rejected, (state) => {
      state.importBudgetModal.isFetchingBudgets = false;
    });
    builder.addCase(fetchQuickbooksBudgets.fulfilled, (state, action) => {
      state.importBudgetModal.availableBudgets = action.payload.map(
        (budget) => ({
          label: budget.name,
          value: budget.id,
        })
      );
      state.importBudgetModal.isFetchingBudgets = false;
    });
    builder.addCase(fetchImportedBudgets.pending, (state) => {
      state.selectBudgetScreen.loading = true;
    });
    builder.addCase(fetchImportedBudgets.fulfilled, (state, action) => {
      state.selectBudgetScreen.importedBudgets = action.payload.items;
      state.selectBudgetScreen.loading = false;
      if (action.payload.items.length === 0) {
        state.status = BvAQBOStatus.EmptyState;
      } else {
        state.status = BvAQBOStatus.SelectingBudget;
      }
    });
    builder.addCase(refreshSelectedBudgets.pending, (state) => {
      state.selectBudgetScreen.isRefreshingBudgets = true;
    });
    builder.addCase(refreshSelectedBudgets.fulfilled, (state, action) => {
      state.selectBudgetScreen.selectedBudgets = {};
      for (
        let index = 0;
        index < state.selectBudgetScreen.importedBudgets.length;
        index++
      ) {
        const budget = state.selectBudgetScreen.importedBudgets[index];
        if (budget.id in action.payload) {
          state.selectBudgetScreen.importedBudgets[index] =
            action.payload[budget.id];
        }
      }
      state.selectBudgetScreen.isRefreshingBudgets = false;
    });
    builder.addCase(deleteBudget.pending, (state) => {
      state.selectBudgetScreen.loading = true;
    });
    builder.addCase(deleteBudget.fulfilled, (state, action) => {
      delete state.selectBudgetScreen.selectedBudgets[action.meta.arg.budgetId];
      state.selectBudgetScreen.importedBudgets =
        state.selectBudgetScreen.importedBudgets.filter(
          (budget) => budget.id !== action.meta.arg.budgetId
        );

      if (state.selectBudgetScreen.importedBudgets.length === 0) {
        state.status = BvAQBOStatus.EmptyState;
      }
      state.selectBudgetScreen.loading = false;
    });
    builder.addCase(getBudgetVsActuals.pending, (state) => {
      state.viewSelectedBudgetScreen.isLoading = true;
    });
    builder.addCase(getBudgetVsActuals.fulfilled, (state, action) => {
      state.viewSelectedBudgetScreen.data = action.payload;
      state.viewSelectedBudgetScreen.isLoading = false;
    });
  },
});

export const qboBudgetsActions = qboBudgetsSlice.actions;
export default qboBudgetsSlice.reducer;
