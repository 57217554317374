import AccountsServices from "@services/APIs/Accounts";
import React, {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { useQuery } from "react-query";

const AgencyContext = createContext({});

export const useAgency = () => useContext(AgencyContext);

const AgencyProvider: FC<PropsWithChildren> = ({ children }) => {
  const slug = useMemo(() => {
    if (typeof window === "undefined") {
      return;
    }
    const currentUrl = window.location.hostname;
    const [subdomain, domain] = currentUrl.split(".");
    if (
      !subdomain ||
      ["app", "admin", process.env.NEXT_PUBLIC_DEFAULT_SLUG].includes(subdomain)
    ) {
      return;
    }

    if (["hub-analytics", "tgg-accounting", "localhost"].includes(domain)) {
      return subdomain;
    }
  }, []);

  const { data, isSuccess, isLoading } = useQuery(
    [
      `/clients/slug/${slug}`,
      {
        slug: slug,
      },
    ],
    AccountsServices.getAgencyDetails,
    { refetchOnWindowFocus: false, enabled: !!slug }
  );

  const value = useMemo(
    () => ({
      agencyName: data?.name || "",
      logoUrl: data?.logoUrl || "",
      agencyId: data?.id || "",
      isLoading,
      isSuccess,
    }),
    [data, isLoading, isSuccess]
  );

  useEffect(() => {
    const currentUrl = window.location.hostname;
    const splittedUrl = currentUrl.split(".");

    const [subdomain, domain] = splittedUrl;

    if (
      !subdomain ||
      !domain ||
      domain === "localhost" ||
      ["app", "admin", process.env.NEXT_PUBLIC_DEFAULT_SLUG].includes(subdomain)
    ) {
      return;
    }

    splittedUrl.shift();

    const newUrl = splittedUrl.join(".");

    window.location.replace(
      `https://${process.env.NEXT_PUBLIC_DEFAULT_SLUG}.${newUrl}/${subdomain}`
    );
  }, []);

  return (
    <AgencyContext.Provider value={value}>{children}</AgencyContext.Provider>
  );
};

export default AgencyProvider;
